@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');


*,*::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.slick-dots li button::before {
  font-size: 8px;
  color: #eee; 
 }


.slick-prev, .slick-next {
  z-index: 1000;
}

.slick-prev:before, .slick-next:before {
  font-size: 40px;
  color: #f9f9f9; 
}


